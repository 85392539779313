import React from 'react'
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServicesOne from '../components/Services/ServicesOne'
import StartProject from '../components/Common/StartProject'
import ServiceInfo from '../components/Services/ServiceInfo'
import { useIntl } from "gatsby-plugin-intl"

const Services = () => {
    const intl = useIntl()
    return (
        <Layout>
            <NavbarTwo bgWhite={false}/>
            <PageBanner
                pageTitle={intl.formatMessage({ id: "services" })}
                homePageText={intl.formatMessage({ id: "home" })} 
                homePageUrl="/" 
                activePageText={intl.formatMessage({ id: "services" })}
            />
            <ServicesOne />
            <ServiceInfo />
            <StartProject />
            <Footer />
        </Layout>
    );
}

export default Services