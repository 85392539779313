import React from 'react';
import {Link} from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"
import StarIcon from '../../assets/images/star-icon.png'
import ServiceIcon1 from '../../assets/images/services/service-icon1.png'
import ServiceIcon2 from '../../assets/images/services/service-icon2.png'
import ServiceIcon3 from '../../assets/images/services/service-icon3.png'

const ServiceInfo = () => {
    const intl = useIntl()
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="image" /> 
                        {intl.formatMessage({ id: "services.ServiceInfo.SubTitle" })}
                    </span>
                    <h2>{intl.formatMessage({ id: "services.ServiceInfo.H2SubTitle" })}</h2>
                    <p>{intl.formatMessage({ id: "services.ServiceInfo.PSubTitle" })}</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={ServiceIcon1} alt="Service Icon" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    {intl.formatMessage({ id: "services.ServiceInfo.DataAnalytics.Title" })}
                                </Link>
                            </h3>
                            <p>{intl.formatMessage({ id: "services.ServiceInfo.DataAnalytics.Content" })}</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={ServiceIcon3} alt="Service Icon" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    {intl.formatMessage({ id: "services.ServiceInfo.DataScience.Title" })}
                                </Link>
                            </h3>
                            <p>{intl.formatMessage({ id: "services.ServiceInfo.DataScience.Content" })}</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={ServiceIcon2} alt="Service Icon" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    {intl.formatMessage({ id: "services.ServiceInfo.AI.Title" })}
                                </Link>
                            </h3>
                            <p>{intl.formatMessage({ id: "services.ServiceInfo.AI.Content" })}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceInfo;