import React from 'react'
import {Link} from 'gatsby'
import StarIcon from '../../assets/images/star-icon.png'
import { useIntl } from "gatsby-plugin-intl"

const ServicesOne = () => {
    const intl = useIntl()
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="image" /> 
                        {intl.formatMessage({ id: "services.ServicesOne.SubTitle" })}
                    </span>
                </div>
            <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                {intl.formatMessage({ id: "services.ServicesOne.Startup.Title" })}
                            </h3>
                            <p>{intl.formatMessage({ id: "services.ServicesOne.Startup.Content" })}</p>

                            <Link style={{ visibility: 'hidden' }} to={`/${intl.locale}/services/case-studies?case=headless-cms`} className="view-details-btn">
                                {intl.formatMessage({ id: "services.ServicesOne.Startup.ViewDetails" })}
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
                            <h3>
                                {intl.formatMessage({ id: "services.ServicesOne.SaaS.Title" })}
                            </h3>
                            <p>{intl.formatMessage({ id: "services.ServicesOne.SaaS.Content" })}</p>
                            
                            <Link to={`/${intl.locale}/services/case-studies?case=website-doanh-nghiep`} className="view-details-btn">
                            {intl.formatMessage({ id: "services.ServicesOne.SaaS.ViewDetails" })}
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>
                            <h3>
                                {intl.formatMessage({ id: "services.ServicesOne.eCommerce.Title" })}
                            </h3>
                            <p>{intl.formatMessage({ id: "services.ServicesOne.eCommerce.Content" })}</p>
                            
                            <Link to={`/${intl.locale}/services/case-studies?case=landing-page`} className="view-details-btn">
                            {intl.formatMessage({ id: "services.ServicesOne.eCommerce.ViewDetails" })}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne